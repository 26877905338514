<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { getBrokerageApi } from '@/api/brokerage'

import BankAccountEditDialog from "./components/bank_account_edit_dialog.vue"
/**
 * Bank Account  component
 */
export default {
  page: {
    title: "Bank Info",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Bank Account",
      items: [
        {
          text: "Setting",
        },
        {
          text: "Bank Account",
          active: true,
        },
      ],
      current_bank_account : {},
      accounts: [],


    };
  },
  components: {
    Layout,
    PageHeader,
    BankAccountEditDialog
  },

  methods: {


    bank_account_save(acc) {
      if (acc.id) {
        getBrokerageApi().configApi.account_setup(acc).then((res) => {
          if (res.errCode == 0) {
            this.$bvModal.hide('modal-bank-account-edit-dialog')
          } else {
            this.$alertify.message("Failed to Update Account " + res.errCode);
          }

        })
      } else {
        getBrokerageApi().configApi.setup_trust_term_account(acc).then((res) => {
          if (res.errCode == 0) {
            this.$bvModal.hide('modal-bank-account-edit-dialog')
          } else {
            this.$alertify.message("Failed to Update Account " + res.errCode);
          }

        })
      }
    },

    edit_bank_account(bank_account) {
      this.current_bank_account = bank_account
      this.$bvModal.show('modal-bank-account-edit-dialog')
    },

    add_bank_account(account_type) {
      this.current_bank_account = {
        is_trust : 1,
        account_type : account_type == 'Term' ? 'bankAccount_type5' : 'bankAccount_type1',
        type : 'CHEQUE',
        name : ''
      }
      this.$bvModal.show('modal-bank-account-edit-dialog')
    }
  },
  mounted() {
    getBrokerageApi().configApi.account_list().then((res) => {
      this.accounts.push(res.data.trust)
      this.accounts.push(res.data.commission)
      this.accounts.push(res.data.pm_trust)
      this.accounts.push(res.data.general)

      res.data.advance_list.map(e => {
        this.accounts.push(e)
      })

      res.data.term_trust_list.map(e => {
        this.accounts.push(e)
      })
      
    })



  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal centerd :id="'modal-bank-account-edit-dialog'" title="Basic Info" size="lg"
                        hide-footer>
      <BankAccountEditDialog :account="current_bank_account"
        @cancel="$bvModal.hide('modal-bank-account-edit-dialog')"
        @confirm="bank_account_save" />
    </b-modal>


    <div class="row">
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                   
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-6">

                <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">

                  <b-dropdown variant="white" right toggle-class="btn-link text-dark shadow-none">
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                    </template>
                     <b-dropdown-item @click="add_bank_account('Term')">Setup Trust Term Account</b-dropdown-item> 
                  </b-dropdown>
                
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->


            <div class="card border shadow-none" v-for="(item, index) in accounts" :key="index">
              <div class="card-body">
                <div class="d-flex">
                  <div class="flex-grow-1 me-2 flex-wrap">
                    <h5 class="font-size-15 mb-1">
                      <router-link to="/projects/overview" class="text-dark">{{ item.name }}</router-link>
                    </h5>
                   
                  </div>
                  <div class="flex-shrink-0">
                    <div class="d-flex gap-2">
                      <div>
                        <a href="#" class="btn btn-light btn-sm"
                          @click="edit_bank_account(item)"><i
                            class="uil uil-pen"></i></a>

                      </div>
                     
                    </div>

                  </div>
                </div>
              </div>
              <!-- end card body -->

              <div class="row g-0">
                <div class="col-xl-2 col-sm-4">
                  <div class="border p-3 h-100">
                    <div>
                      <p class="text-muted font-size-13 mb-2">
                        Open Balance
                      </p>
                      <div class="badge badge-soft-primary font-size-12">
                        {{ item.account_balance?'$'+item.account_balance.toLocaleString():'' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-sm-4">
                  <div class="border p-3 h-100">
                    <div>
                      <p class="text-muted font-size-13 mb-2">
                        Account
                      </p>
                      <div class="badge badge-soft-primary font-size-12">
                        {{ item.a_account }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-sm-4">
                  <div class="border p-3 h-100">
                    <div>
                      <p class="text-muted font-size-13 mb-2">
                        Transit
                      </p>
                      <h5 class="font-size-14 mb-0">{{ item.a_transit }}</h5>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-sm-4">
                  <div class="border p-3 h-100">
                    <div>
                      <p class="text-muted font-size-13 mb-2">
                        Institution
                      </p>

                      {{ item.financial_institution }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-sm-4">
                  <div class="border p-3 h-100">
                    <div>
                      <p class="text-muted font-size-13 mb-2">
                        Account GL
                      </p>
                      <div class="badge font-size-12" :class="{ 'bg-success': 'Completed', }">
                        {{ item.gl_account }}
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-sm-4" v-if="item.is_trust ==1">
                  <div class="border p-3 h-100">
                    <div>
                      <p class="text-muted font-size-13 mb-2">
                        Account GL Liability
                      </p>

                      <div class="badge font-size-12" :class="{ 'bg-success': 'bg-warning' }">
                        {{ item.gl_liability_account }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>

          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
