<script>


/**
 * Basic Info Edit component
 */


 import {
  required,
} from "vuelidate/lib/validators";
import Choices from "choices.js";

import GLAutoComplete from "@/components/gl-auto-complete-input.vue";



export default {

    emits: ['confirm', 'cancel'],

    props: ['account'],
    components: {
        GLAutoComplete,
    },

    validations: {
        account: {
            name: { required },
            a_account: { required },
            financial_institution : {required},
            a_transit : {required}
        },
    },

    

    data() {
      
        return {
            submitted : false,
            gl_choice: {},
            liability_gl_choice:{} ,
            gl_items:[],
            gl_liability_items:[],
            account_type_list : [
                {label : 'Trust',       value :'bankAccount_type1', selected: true},
                {label : 'Commission',  value :'bankAccount_type2'},
                {label : 'PM Trust',    value :'bankAccount_type3'},
                {label : 'Term',        value :'bankAccount_type5'},
                {label : 'General',     value :'bankAccount_type4'},
                {label : 'Advance',     value :'bankAccount_type6'},

            ]
        }
       
    },
    methods: {
        formSubmit() {
            this.submitted = true;     
            this.$v.$touch();
            if (this.$v.$error == false) {
                this.$emit('confirm', this.account)
            }
        },

       

        onGLSelected(e, type) {
            if (type == 'gl') {
                this.account.gl_account = e.value
            } else {
                this.account.gl_liability_account = e.value
            }
        },

        onAccountTypeChange() {
            this.account.is_trust = (this.account.account_type == 'bankAccount_type1' || this.account.account_type == 'bankAccount_type3' || this.account.account_type== 'bankAccount_type5') ?1 : 0
        }
    },

    created() {
     
      
    },

    mounted() {

       
        this.$refs['gl_account'].setValue(this.account.gl_account)
        this.$refs['gl_liability_account'].setValue(this.account.gl_liability_account)

        new Choices('#pay_type', {})
        new Choices('#eft_type', {})
        
        
        this.account_type_list.map(e => e.selected = e.value == this.account.account_type? true : false)
        new Choices('#account_type', {choices : this.account_type_list})


    }

};
</script>

<template>
    <div class="card">
        <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">Bank Information</h4>
        </div>
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom02">Name</label>
                            <input id="validationCustom02" v-model="account.name" type="text"
                                class="form-control" placeholder="" value="" :class="{
                                    'is-invalid': submitted && $v.account.name.$error,
                                }" />
                            <div v-if="submitted && $v.account.name.$error" class="invalid-feedback">
                                <span v-if="!$v.account.name.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">Account Type</label>
                            <select id="account_type" class="form-contorl" v-model="account.account_type" @change="onAccountTypeChange"></select>
                           
                           
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label >Type</label>
                                <select
                                    class="form-control"
                                    data-trigger
                                    v-model="account.type"
                                    id="pay_type"
                                    >
                                    <option value="CHEQUE" selected>Cheque</option>
                                    <option value="EFT">EFT</option>
                                </select>
                                
                            </div>
                        </div>
                    </div>
                  
                </div>
                 <!-- end row-->

                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom03">Institution</label>
                            <input id="validationCustom03" v-model="account.financial_institution" type="text"
                                class="form-control" placeholder="" :class="{
                                    'is-invalid': $v.account.a_transit.$error,
                                }" />
                             <div v-if="submitted && $v.account.financial_institution.$error" class="invalid-feedback">
                                <span v-if="!$v.account.financial_institution.required">This value is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom04">Transit</label>
                            <input id="validationCustom04" v-model="account.a_transit" type="text"
                                class="form-control" placeholder=""   :class="{
                                    'is-invalid': $v.account.a_transit.$error,
                                }"  />
                            <div v-if="submitted && $v.account.a_transit.$error" class="invalid-feedback">
                                <span v-if="!$v.account.a_transit.required">This value is required.</span>
                            </div>
                        </div>
                       
                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom05">Account</label>
                            <input id="validationCustom05" v-model="account.a_account" type="text"
                                class="form-control" placeholder=""  :class="{
                                    'is-invalid': submitted && $v.account.a_account.$error,
                                }" />
                            <div v-if="submitted && $v.account.a_account.$error" class="invalid-feedback">
                                <span v-if="!$v.account.a_account.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                   
                </div>
                 <!-- end row-->

                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                            <div class="mb-3">
                            <label>GL Account</label>
                            <GLAutoComplete v-model="account.gl_account" ref="gl_account" @onSelected="onGLSelected($event, 'gl')"/>
                           
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4" :class="account.is_trust == 1?'':'d-none'">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label>GL Liability</label>
                                <GLAutoComplete v-model="account.gl_liability_account" ref="gl_liability_account" @onSelected="onGLSelected($event, 'liability')" />
                              
                                
                            </div>
                        </div>
                    </div>
                </div>
                 <!-- end row-->




                <div class="row">

                    <div class="col-md-4">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label >File Format</label>
                                <select
                                    class="form-control"
                                    data-trigger
                                    v-model="account.eft_format"
                                    id="eft_type"
                                    >
                                    <option value="FILE80" selected>File 005 Format</option>
                                    <option value="FILE005">File 80 Format</option>
                                </select>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="validationCustom01">Originator Cleaer Id</label>
                                <input
                                    class="form-control"
                                    data-trigger
                                    v-model="account.originator_id"
                                    name="originator_id"
                                    id="originator_id"
                                    :disabled="account.type == 'CHEQUE'"
                                    />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="validationCustom01">PAD Originator Id</label>
                                <input
                                    class="form-control"
                                    data-trigger
                                    v-model="account.pad_originator_id"
                                    name="originator_id"
                                    id="originator_id"
                                    :disabled="account.type == 'CHEQUE'"
                                    />
                            </div>
                        </div>
                    </div>

                   
                </div>
                <!-- end row-->


                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="validationCustom01">Data Center Code</label>
                                <input
                                    class="form-control"
                                    data-trigger
                                    v-model="account.data_center"
                                    name="data_center"
                                    :disabled="account.type == 'CHEQUE'"
                                    />
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for="validationCustom01">CPA</label>
                                <input
                                    class="form-control"
                                    data-trigger
                                    v-model="account.cpa"
                                    name="cpa"
                                    :disabled="account.type == 'CHEQUE'"
                                    />
                                
                            </div>
                        </div>
                    </div>
                </div>
                    <!-- end row-->


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>